import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import PageHeader from "../components/PageHeader"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import {  animeFadeUp, animeSlideOutRight } from "../utils/Animations"
const CocktailsPage = ({ data, transitionStatus }) => {

  const cocktails = [
    {
      name: 'Bunny Bunny Boom',
      image: data.bunnybunnyboom,
      ingredients: [
        'Vodka',
        'Sirop de fruit de la passion',
        'Jus de citron jaune',
        'Jus d\'orange',
        'Jus de carotte'
      ]
    },
    {
      name: 'Cinzano Cup',
      image: data.cinzanocup,
      ingredients: [
        'Cinzano Aperitivo',
        'Jus de citron jaune',
        'Liqueur falernum maison',
        'Liqueur d’abricot',
        'Jus de pomme',
        'Orange',
        'Citron jaune',
        'Menthe',
        'Concombre',
        'Ginger ale'
      ]
    },
    {
      name: 'Whisky Sour',
      image: data.sour,
      ingredients: [
        'Whisky',
        'Jus de citron jaune',
        'Sirop de sucre',
        'Blanc d’œuf',
        'Angostura bitters',
      ]
    },
    {
      name: 'Paloma n°2',
      image: data.paloma,
      ingredients: [
        'Téquila altos',
        'Sirop de fleur de sureau',
        'Jus de citron vert',
        'Jus de pamplemousse',
        'Eau pétillante',
      ]
    },
    {
      name: 'Raspberry choc',
      image: data.raspberrychoc,
      ingredients: [
        'Vodka infusée à la menthe',
        'Purée de framboise',
        'Jus de citron jaune',
        'Sirop de sucre',
        'Liqueur de cacao brun',
        'Blanc d\'oeuf',
      ]
    },
    {
      name: 'Mojito',
      image: data.mojito,
      ingredients: [
        'Rhum Havana 3 ans',
        'Sucre en poudre',
        'Jus de citron vert',
        'Menthe',
        'Eau pétillante'
      ]
    },
    {
      name: 'Spicy raspberry mule',
      image: data.spicyraspberrymule,
      ingredients: [
        'Rhum Havana Especial',
        'Sirop aux épices maison',
        'Jus de citron vert',
        'Purée de framboise',
        'Ginger beer'
      ]
    },
    {
      name: 'E & Tea',
      image: data.etea,
      ingredients: [
        'Liqueur de fleur de sureau',
        'Jus de citron jaune',
        'Sirop de sucre',
        'Liqueur de pêche',
        'Thé vert glacé maison',
      ]
    }
  ]
  const listCocktails = cocktails.map((cocktail, index) =>
    <InView key={index} triggerOnce={true} threshold={0.35} skip={transitionStatus !== "entered"}>
      {({ inView, ref, entry }) => (
        <motion.div
          initial={false}
          animate={inView ? "visible" : "hidden"}
          ref={ref}
          className="grid grid-cols-1 md:grid-cols-12"
        >
          <motion.div
            initial={false}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  ease: "easeOut",
                  duration: "0.6",
                  staggerChildren: 0.4
                }
              },
              hidden: {
                opacity: 0,
                y: '40px'
              }
            }}
            className="bg-white py-12 my-0 flex items-center z-20 px-8 lg:my-12 md:px-14 md:row-start-1 md:col-start-1 md:col-end-7 lg:col-end-6 xl:col-end-6 2xl:col-end-5 md:py-20 2xl:py-24 2xl:px-20">
            <div>
              <motion.h2 variants={animeFadeUp} className="display-2">
                {cocktail.name}
              </motion.h2>
              <motion.div
                variants={{
                  hidden:{
                    width: 0
                  },
                  visible: {
                    width: '6rem',
                    transition: {
                      ease: "easeOut",
                      duration: "0.6"
                    }
                  }
                }}
                className="border-b-4 border-copper mt-4 mb-8"
              />
              <motion.ul
                initial={false}
                variants={{
                  visible: {
                    transition: {
                      staggerChildren: 0.07
                    }
                  }
                }}
              >
                {cocktail.ingredients.map((ingredient, index) =>
                  <motion.li
                    key={index}
                    variants={{
                      hidden:{
                        opacity:0,
                        y: '20px'
                      },
                      visible: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          y: { stiffness: 1000, velocity: -100 }
                        }
                      }
                    }}
                    className="text-xl mb-2 ">{ingredient}
                  </motion.li>
                )}
              </motion.ul>
            </div>
          </motion.div>
          <div className="row-auto h-64 order-first relative md:order-none md:h-auto md:row-start-1 md:col-start-7 md:col-end-13 lg:col-start-4">
            <div className="absolute inset-0 overflow-hidden">
              <motion.div variants={animeSlideOutRight} className="absolute inset-0 bg-pampas z-10"/>
              <Img
                className="h-full"
                fluid={cocktail.image.childImageSharp.fluid}
              />
            </div>
          </div>
        </motion.div>
      )}
    </InView>
  )
  return (
    <Layout>
      <SEO
        title="Carte des cocktails - Belladrinks bar à cocktails événementiel"
        description="Découvrez notre carte des cocktails. Nous réalisons tous les grands classiques du bar ainsi que des créations sur-mesure pour les mariages et les événements professionels."
      />
      <PageHeader data={data} transitionStatus={transitionStatus} title={"Carte des cocktails"}/>
      <section className="py-16 md:py-40 2xl:py-56 2xl:pb-48 bg-white">
        <InView triggerOnce={true} threshold={0.5} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
              className="main-container grid grid-cols-1 xl:grid-cols-2 lg:gap-4" ref={ref}
            >
              <div>
                <motion.h2 variants={animeFadeUp} className="display-1 mb-8 pr-10">Sucré, Amère, Fruité, Acide, Tourbé, Florale.</motion.h2>
                <motion.div
                  variants={{
                    hidden:{
                      width: 0
                    },
                    visible: {
                      width: '7rem',
                      transition: {
                        ease: "easeOut",
                        duration: "0.6"
                      }
                    }
                  }}
                  className="border-b-4 border-copper mt-2 mb-8"
                />
              </div>
              <div>
                <motion.p variants={animeFadeUp} className="lead">
                  La sélection de cocktails ci-dessous reflète nos inspirations du moment. Nous réalisons tous les grands classiques du bar ainsi que des créations sur-mesure pour nos clients. Contactez-nous pour un devis personnalisé.
                </motion.p>
              </div>
            </motion.div>
          )}
        </InView>
      </section>
      <section className="py-24">
        <div className="main-container gap-y-8 lg:gap-y-24 grid md:grid-flow-row md:auto-rows-fr">
          {listCocktails}
        </div>
      </section>
    </Layout>
  )
}

export default CocktailsPage

export const query = graphql`
    query {
        backgroundHeader: file(relativePath: { eq: "header/header_cocktails.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bunnybunnyboom: file(relativePath: { eq: "cocktails/bunnybunnyboom.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cinzanocup: file(relativePath: { eq: "cocktails/cinzanocup.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        etea: file(relativePath: { eq: "cocktails/etea.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        mojito: file(relativePath: { eq: "cocktails/mojito.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        paloma: file(relativePath: { eq: "cocktails/paloma.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        sour: file(relativePath: { eq: "cocktails/sour.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        raspberrychoc: file(relativePath: { eq: "cocktails/raspberrychoc.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        spicyraspberrymule: file(relativePath: { eq: "cocktails/spicyraspberrymule.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
